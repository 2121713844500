<template>
  <div class="topbar">
    <div
      v-if="
        isClub
          ? availableLanguages?.length > 1
          : !['login-set-password', 'login-activation', 'cart-checkout', 'cart-login'].includes($route.name)
      "
      class="mobile-top-header u-bg-dark-blue show-f-md u-f-space-between"
    >
      <div class="mobile-top-header-item">
        <ClientOnly>
          <LangSwitcher v-if="availableLanguages?.length > 1" />
        </ClientOnly>
      </div>
      <div
        v-show="
          !isClub && !['login-set-password', 'login-activation', 'cart-checkout', 'cart-login'].includes($route.name)
        "
        class="mobile-top-header-item nav-user-zip-code u-c-white"
        @click="openRightSidebarModal()"
      >
        <div class="icon-location u-bg-white" />
        <div>
          <span v-if="userZipCode">{{ userZipCode }}</span>
          <span v-else>{{ $t('header.yourLocation') }}</span>
        </div>
      </div>
    </div>
    <header class="header">
      <BurgerMenu
        v-if="!isClub && !isLoading && !['cart-login', 'cart-checkout']?.includes($route.name)"
        ref="burgerMenu"
        data-test-id="burger-menu"
      />
      <div ref="headerContainer" class="header-container" data-test-id="header-container">
        <div>
          <div ref="menuTop" class="menu-top" data-test-id="menu-top">
            <div class="u-d-f u-ai-c">
              <MainButton
                v-show="isMd && !isClub && !['cart-login', 'cart-checkout']?.includes($route.name)"
                tag="button"
                class-variant="btn-icon icon-only burger-button-sm"
                title="burger-menu"
                @click="callToggleMenu"
              >
                <div class="icon-menu u-bg-dark-blue" />
              </MainButton>
              <NuxtLinkLocale
                v-if="isClub && currentClub"
                :to="'index'"
                class="hide-sm"
                :data-test-id="`club-logo-${clubName?.replace(/\s+/g, '-')?.toLowerCase()}`"
              >
                <img
                  v-if="clubLogo"
                  :src="clubLogo"
                  :alt="$t('club.clubLogoAlt', { name: clubName })"
                  :title="$t('club.clubLogoAlt', { name: clubName })"
                  class="logo"
                />
              </NuxtLinkLocale>
              <NuxtLinkLocale
                v-else-if="!isClub"
                :to="'index'"
                :class="['cart-login', 'cart-checkout']?.includes($route.name) ? 'p-without-bg-menu' : ''"
                data-test-id="home-logo-stockpro"
              >
                <img
                  src="~/assets/img/svg/logos/logo-stockpro-color.svg"
                  :alt="$t('header.logoAlt')"
                  :title="$t('header.logoAlt')"
                  width="150"
                  height="34"
                  class="logo"
                />
              </NuxtLinkLocale>
            </div>
            <ClientOnly>
              <div class="searchbar-container" data-test-id="searchbar-container" :class="!isClub ? 'hide-sm' : ''">
                <SearchBar
                  v-show="showSearchBar"
                  id="searchbar-md"
                  key="searchbar-md"
                  data-test-id="home-searchbar-input"
                />
              </div>
              <div class="u-d-f">
                <LangSwitcher
                  v-if="availableLanguages?.length > 1"
                  v-show="
                    !isClub &&
                    !['login-set-password', 'login-activation', 'cart-checkout', 'cart-login'].includes($route.name)
                  "
                  class="hide-md"
                />
                <div
                  v-show="
                    !isClub &&
                    !['login-set-password', 'login-activation', 'cart-checkout', 'cart-login'].includes($route.name)
                  "
                  class="hide-md nav-user-zip-code"
                  @click="openRightSidebarModal()"
                >
                  <div class="icon-location u-bg-dark-blue" />
                  <div>
                    <span v-if="userZipCode">{{ userZipCode }}</span>
                    <span v-else>{{ $t('header.yourLocation') }}</span>
                  </div>
                </div>
                <div
                  v-show="isClub || !['cart-login', 'cart-checkout']?.includes($route.name)"
                  class="nav-user-wrapper"
                  data-test-id="nav-user"
                  :class="{ 'submenu-toggle': user.isAuthenticated, 'is-active': showMenu }"
                  @mouseover="user.isAuthenticated ? (showMenu = true) : null"
                  @mouseleave="user.isAuthenticated ? (showMenu = false) : null"
                >
                  <span v-if="user.isAuthenticated" class="user-info" data-test-id="nav-user-info">
                    <span class="user-name" data-test-id="user-name">{{ user.fullName }}</span>
                    <span
                      v-if="user.isPro"
                      class="user-company u-c-primary"
                      :data-test-id="user.isPro ? 'user-company' : ''"
                    >
                      {{ user.companyName }}
                    </span>
                  </span>
                  <NuxtLinkLocale v-else :to="'login'" class="user-login" data-test-id="nav-user-login">
                    {{ $t('header.login') }}
                  </NuxtLinkLocale>
                  <div class="nav-user" @mouseleave="showCompanyList = false">
                    <MainButton
                      :link="user.isAuthenticated ? '' : localePath('login')"
                      class-variant="btn-icon"
                      aria-label="User button"
                    >
                      <div class="icon-user u-bg-dark-blue" data-test-id="nav-user-icon" />
                    </MainButton>
                    <nav
                      v-if="user.isAuthenticated"
                      class="submenu"
                      data-test-id="nav-user-submenu"
                      :class="{ 'nav-user-submenu-company-switch-active': showCompanyList }"
                      @click="!showCompanyList ? (showMenu = false) : null"
                    >
                      <ul>
                        <li v-if="$route.path != '/'">
                          <NuxtLinkLocale
                            :to="'index'"
                            :title="$t('pages.home')"
                            class="pointer"
                            data-test-id="nav-user-submenu-home"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-home.svg"
                              class="header-ico-scale"
                              width="19"
                              :alt="$t('pages.home')"
                              :title="$t('pages.home')"
                            />
                            <span>{{ $t('pages.marketplace') }}</span>
                          </NuxtLinkLocale>
                        </li>
                        <li v-if="$route.path != 'stock' && stockButton">
                          <NuxtLinkLocale
                            :to="'stock'"
                            :title="$t('pages.stock')"
                            class="pointer"
                            data-test-id="nav-user-submenu-stock"
                          >
                            <img
                              src="@/assets/img/svg/icons/stock-pro/ico-stock-pro.svg"
                              class="header-ico-scale"
                              width="19"
                              :alt="$t('pages.stock')"
                              :title="$t('pages.stock')"
                            />
                            <span>{{ $t('pages.stock') }}</span>
                          </NuxtLinkLocale>
                        </li>
                        <li
                          v-if="
                            isClub && !clubEnablePayment && !user.isSalesRep && !user.isPriceTeam && !user.isSalesAdmin
                          "
                        >
                          <NuxtLinkLocale
                            :to="'my-account-quotes'"
                            :title="$t('header.myQuotes')"
                            data-test-id="nav-user-submenu-quotes"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-order.svg"
                              width="19"
                              :alt="$t('header.myQuotes')"
                              :title="$t('header.myQuotes')"
                              data-test-id="nav-user-submenu-quotes-icon"
                            />
                            <span>
                              {{ $t('header.myQuotes') }}
                            </span>
                          </NuxtLinkLocale>
                        </li>
                        <li v-if="isClub ? !user.isSalesRep && !user.isPriceTeam && !user.isSalesAdmin : true">
                          <NuxtLinkLocale
                            :to="'my-account-orders'"
                            :title="$t('header.myOrders')"
                            data-test-id="nav-user-submenu-orders"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-order.svg"
                              width="19"
                              :alt="$t('header.myOrders')"
                              :title="$t('header.myOrders')"
                              data-test-id="nav-user-submenu-orders-icon"
                            />
                            <span>
                              {{ $t('header.myOrders') }}
                            </span>
                          </NuxtLinkLocale>
                        </li>
                        <li v-if="isClub && (user.isSalesRep || user.isPriceTeam || user.isSalesAdmin)">
                          <NuxtLinkLocale
                            :to="'my-account-quotes-sales'"
                            :title="$t('header.myQuoteSales')"
                            data-test-id="nav-user-submenu-quote-sales"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-order.svg"
                              width="19"
                              :alt="$t('header.myQuoteSales')"
                              :title="$t('header.myQuoteSales')"
                            />
                            <span>{{ $t('header.myQuoteSales') }}</span>
                          </NuxtLinkLocale>
                        </li>
                        <li v-if="isClub ? user.isSalesRep || user.isPriceTeam || user.isSalesAdmin : user.isPro">
                          <NuxtLinkLocale
                            :to="'my-account-sales'"
                            :title="$t('header.mySales')"
                            data-test-id="nav-user-submenu-sales"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-order.svg"
                              width="19"
                              :alt="$t('header.mySales')"
                              :title="$t('header.mySales')"
                            />
                            <span>{{ $t('header.mySales') }}</span>
                          </NuxtLinkLocale>
                        </li>
                        <li v-if="!isClub">
                          <NuxtLinkLocale
                            :to="'my-account-mkp-alerts'"
                            :title="$t('header.myAlerts')"
                            data-test-id="nav-user-submenu-alerts"
                          >
                            <img
                              src="@/assets/img/svg/icons/notification/ico-notification.svg"
                              width="19"
                              :alt="$t('header.myAlerts')"
                              :title="$t('header.myAlerts')"
                            />
                            <span>{{ $t('header.myAlerts') }}</span>
                          </NuxtLinkLocale>
                        </li>
                        <CompanySelector
                          v-if="isClub ? !user.isSalesRep && !user.isPriceTeam && !user.isSalesAdmin : user.isPro"
                          :show-company-list="showCompanyList"
                          data-test-id="nav-user-submenu-company-switch"
                          @update-show-company-list="showCompanyList = $event"
                        />
                        <template v-if="isSm && user?.clubs?.length">
                          <template v-for="club in user.clubs" :key="club.uid">
                            <li v-if="clubUid !== club.uid">
                              <a :href="getClubUrl(club)">
                                <img
                                  src="@/assets/img/svg/icons/ico-club-header.svg"
                                  width="19"
                                  class="scaled"
                                  :alt="$t('club.clubName', { name: club.name })"
                                  :title="$t('club.clubName', { name: club.name })"
                                />
                                <span>{{ $t('club.clubName', { name: club.name }) }}</span>
                              </a>
                            </li>
                          </template>
                        </template>
                        <ClientOnly>
                          <li
                            v-if="
                              !isClub &&
                              ((user.isPro && user.isOwner && !user.company.stripe_url) || user.isAdmin) &&
                              tld === 'FR'
                            "
                          >
                            <NuxtLinkLocale
                              :to="'my-account-onboarding'"
                              :title="$t('header.becomeSeller')"
                              data-test-id="nav-user-submenu-become-seller"
                            >
                              <img
                                src="@/assets/img/svg/icons/ico-upgrade.svg"
                                width="19"
                                :alt="$t('header.becomeSeller')"
                                :title="$t('header.becomeSeller')"
                              />
                              <span>{{ $t('header.becomeSeller') }}</span>
                            </NuxtLinkLocale>
                          </li>
                        </ClientOnly>
                        <li>
                          <NuxtLinkLocale
                            :to="'index'"
                            :title="$t('header.logOut')"
                            data-test-id="nav-user-submenu-logout"
                            @click="logout"
                          >
                            <img
                              src="@/assets/img/svg/icons/ico-connect-red.svg"
                              width="19"
                              :alt="$t('header.logOut')"
                              :title="$t('header.logOut')"
                            />
                            <span>{{ $t('header.logOut') }}</span>
                          </NuxtLinkLocale>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div
                  v-if="
                    isClub
                      ? !['login', 'login-set-password', 'login-activation', 'cart-login']?.includes($route.name)
                      : !['cart-login', 'cart-checkout']?.includes($route.name)
                  "
                  class="nav-cart"
                  data-test-id="nav-cart"
                >
                  <MainButton
                    :badge="cartQuantity"
                    :link="localePath('cart')"
                    element-test="nav-cart"
                    class-variant="btn-icon icon-only"
                    aria-label="Cart button"
                    @click="onOpenCart"
                  >
                    <div class="icon-cart u-bg-dark-blue" data-test-id="nav-cart-icon" />
                  </MainButton>
                </div>
              </div>
            </ClientOnly>
          </div>
          <div v-if="!isClub" class="menu-bottom" data-test-id="menu-bottom">
            <div
              v-show="routesTopNav.some(v => $route.name?.split('-')[0]?.includes(v))"
              class="menu-bottom-link hide-sm"
            >
              <div class="menu-bottom-link-item" data-test-id="home-all-categories-btn" @click="openMenuCategories()">
                <p>{{ $t('actions.allCategories') }}</p>
              </div>

              <NuxtLinkLocale
                :to="'category-new-products'"
                class="menu-bottom-link-item"
                data-test-id="home-btn-new-products"
                :class="$route.name?.includes('category-new-products') ? 'active' : ''"
              >
                <p>{{ $t('actions.newProducts') }}</p>
              </NuxtLinkLocale>

              <NuxtLinkLocale
                :to="'category-best-deals'"
                class="menu-bottom-link-item"
                data-test-id="home-btn-best-deals"
                :class="$route.name?.includes('category-best-deals') ? 'active' : ''"
              >
                <p>{{ $t('actions.bestDeals') }}</p>
              </NuxtLinkLocale>

              <a
                id="header-pro-button"
                :href="proUrl"
                class="menu-bottom-link-item-primary"
                data-test-id="home-btn-for-pro"
              >
                <p>{{ $t('actions.forThePro') }}</p>
              </a>
            </div>
            <div
              v-if="
                isClub
                  ? false
                  : !['login', 'login-set-password', 'login-activation', 'cart-checkout', 'cart-login']?.includes(
                      $route.name
                    )
              "
              class="searchbar-container show-sm"
            >
              <SearchBar id="searchbar-sm" key="searchbar-sm" />
            </div>
          </div>
          <div class="header-banner-event-cls u-m-b-sm">
            <div
              v-if="!isClub && topBanner"
              :id="topBanner.ID"
              class="header-banner-event"
              v-html="topBanner.content"
            />
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import { useDynamicContentStore } from '@/stores/dynamicContent'
import { useUserStore } from '@/stores/user'
import { useEmitterStore } from '@/stores/emitter'

import BurgerMenu from '@/components/BurgerMenu/BurgerMenu'
import SearchBar from '@/components/SearchBar/SearchBar'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LangSwitcher from '@/components/I18n/LangSwitcher'
import CompanySelector from '@/components/CompanySelector/CompanySelector'

const isSmallerMd = ref(false)
const showCompanyList = ref(false)
const showMenu = ref(false)
const proUrl = ref('')
const routesTopNav = ref(['index', 'default', 'product', 'category', 'brand', 'search'])
const isLoading = ref(true)
const { isClub, currentClub, clubName, clubLogo, clubEnablePayment } = useClub()
const { tld } = useCurrentLocale()

const { isSm, isMd } = useDeviceSize()
const route = useRoute()
const router = useRouter()
const { getProUrl } = useUrl()
const burgerMenu = ref(null)
const { availableLanguages } = usei18nMx()

const showSearchBar = computed(() => {
  return (
    isAutoTest.value ||
    (!isSm.value &&
      !['login', 'login-set-password', 'login-activation', 'cart-checkout', 'cart-login']?.includes(route.name))
  )
})
const cartQuantity = computed(() => {
  const { $cart } = useNuxtApp()
  return $cart().totalItems?.toString()
})
const user = computed(() => {
  const userStore = useUserStore()
  return userStore
})
const topBanner = computed(() => {
  const dynamicContentStore = useDynamicContentStore()
  const topBanner = dynamicContentStore.getTopBanner
  if (topBanner && typeof topBanner?.title === 'object') {
    return { content: renderRichText(topBanner.title), ID: topBanner.ID }
  }
  return null
})
const isAutoTest = computed(() => {
  return route.params.axeptio_disabled === 1 || route.params.location_modal_disabled === 1
})
const stockButton = computed(() => {
  return !isClub.value && user.value.isPro && !user.value.isFreemium
})
const userZipCode = computed(() => {
  const userStore = useUserStore()
  return userStore.getZipCode
})

onMounted(() => {
  isLoading.value = false
  onResize()
  window.onresize = onResize
  proUrl.value = getProUrl()
})

const logout = () => {
  const { $cart } = useNuxtApp()
  const userStore = useUserStore()
  const { analytics, events } = useAnalytics()
  userStore.logout().finally(() => {
    analytics.sendEvent(events.LOGOUT)
    $cart().setIsReady(false)
    const localePath = useLocalePath()
    router.replace(localePath('index'))
  })
}
const onResize = () => {
  if (window.innerWidth <= 1024) isSmallerMd.value = true
  else isSmallerMd.value = false
}

const openMenuCategories = () => {
  const { events } = useAnalytics()
  const emitter = useEmitterStore()
  emitter.emit(events.OPEN_BURGER_MENU_CATEGORIES)
}

const getClubUrl = club => {
  if (import.meta.client) {
    return window.location.host.includes('www')
      ? `${window.location.protocol}//${window.location.host.replace('www', club.key)}`
      : `${window.location.protocol}//${club.key}.${window.location.host}`
  } else {
    return ''
  }
}
const onOpenCart = () => {
  const cartStore = useCartStore()
  cartStore.setPreviousRoute({
    name: route.name,
    params: route.params,
    query: route.query,
  })
  const { analytics, events } = useAnalytics()
  analytics.sendTagEvent(events.TAG_PLAN.MKP.CLICK_CART)
}

const callToggleMenu = () => {
  if (burgerMenu.value?.toggleMenu && typeof burgerMenu.value?.toggleMenu === 'function') {
    burgerMenu.value?.toggleMenu()
  }
}
const openRightSidebarModal = () => {
  const emitter = useEmitterStore()
  emitter.emit('openRightSidebarModalZipCode')
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/components/submenu';

.header {
  position: relative;
  top: 0;
  width: 100%;
  padding-right: $spacing-md;
  background-color: var(--white);
  z-index: 2;

  @include mq($mq-sm) {
    left: 0;
    width: auto;
    padding: 0 $spacing-sm;
    border: none;
  }

  .header-container {
    border-bottom: 1px solid var(--light-grey);
    background-color: var(--white);

    &.transitions {
      transition:
        top 0.5s ease,
        transform 0.5s ease;
    }

    &.is-fixed {
      position: fixed;
      left: $navbar-left-width;
      right: $spacing-md;

      @include mqmin($content-max-width) {
        width: calc($content-max-width - $navbar-left-width - $spacing-md);
        left: unset;
        right: unset;
        padding: unset;
      }

      @include mq($mq-sm) {
        left: 0;
        right: 0;
        padding: 0 $spacing-sm;
      }

      &::after {
        content: '';
        width: $spacing-md;
        position: absolute;
        height: 100%;
        background-color: var(--white);
        top: 0;
        right: -$spacing-md;
      }
    }

    &.is-showing.is-fixed {
      transform: translateY(100%);
    }

    @include mq($mq-sm) {
      border-bottom: none;
    }

    .inner-container {
      padding-left: 0;
    }

    .menu-top {
      display: flex;
      align-items: center;
      padding: $header-padding-v 0 $header-padding-v 0;
      height: 80px;

      @include mq($mq-md) {
        padding: $header-padding-v-sm $spacing-sm $spacing-sm 0;
        justify-content: space-between;
      }

      .burger-button-sm {
        top: 2px;
        margin: 0 $spacing-sm;
      }

      .p-without-bg-menu {
        @include mq($mq-sm) {
          padding-left: $spacing-sm;
        }
      }

      .logo {
        position: relative;
        top: 4px;
        max-height: 35px;
        max-width: 150px;
        @include mq($mq-sm) {
          max-width: 120px;
        }

        &.logo-full {
          @include mq($mq-md) {
            display: none;
          }
        }

        &.logo-min {
          top: 0;
          display: none;

          @include mq-btw($mq-sm, $mq-md) {
            display: block;
          }
        }
      }
      .searchbar-container {
        flex-grow: 1;
        flex-shrink: 1;

        .searchbar {
          margin-left: $spacing-xl;

          @include mq($mq-md) {
            margin: 0 $spacing-xl;
          }

          @include mq($mq-sm) {
            margin: 0 $spacing-sm;
          }
        }
      }

      .mkp-pro-btn {
        margin-left: 20px;
        flex-shrink: 0;

        @include mq($mq-sm) {
          display: none;
        }
      }

      .nav-user-wrapper {
        margin-left: $spacing-xl;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        padding-left: $spacing-md;
        border-radius: 100px;
        transition: all 0.3s ease;

        @include mq($mq-md) {
          margin-left: 0;
          padding-left: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--bg-grey);
        }

        .user-info {
          text-decoration: none;

          @include mq($mq-sm) {
            display: none;
          }

          .user-name {
            display: block;
            color: var(--night-blue);
            font-weight: 800;
            font-size: 1rem;
            @include line-clamp(1);
            max-width: 200px;

            @include mq($mq-md) {
              max-width: 100px;
            }
          }

          .user-company {
            display: block;
            font-weight: 800;
            font-size: 0.875rem;
            @include line-clamp(1);
            max-width: 200px;

            @include mq($mq-md) {
              max-width: 100px;
            }
          }
        }

        .user-login {
          color: var(--night-blue);
          font-weight: 800;
          font-size: 1rem;
          text-decoration: none;

          @include mq($mq-sm) {
            display: none;
          }
        }

        .nav-user {
          display: flex;
          align-items: center;

          li img.scaled {
            transform: scale(1.15);
          }
          & .nav-user-submenu-company-switch-active {
            visibility: visible;
            opacity: 1;
          }
          @include mq($mq-sm) {
            margin-left: 0;
          }
        }
      }

      .nav-cart {
        margin-left: $spacing-sm;
        display: flex;
        align-items: center;

        @include mq($mq-md) {
          margin-left: 0;
          padding-left: 0;
        }
      }
    }

    .menu-bottom {
      padding-bottom: $spacing-sm;

      @include mq($mq-sm) {
        padding-top: $spacing-sm;
      }

      &-link {
        display: flex;
        gap: $spacing-xxl;
        padding-top: 12px;

        &-item,
        &-item-primary {
          cursor: pointer;
          text-decoration: none;
          padding: $spacing-xs $spacing-sm;

          p {
            font-weight: 500;
            font-size: pxToRem(15px) !important;
            line-height: 1;
          }

          &:hover {
            border-radius: $spacing-xs;
          }
          &.active {
            text-decoration: underline;
          }
        }

        &-item-primary {
          p {
            color: var(--primary) !important;
          }
        }

        &-item:hover {
          background-color: var(--bg-grey);
        }

        &-item-primary:hover {
          background-color: var(--primary);
          p {
            color: var(--white) !important;
          }
        }
      }
    }
  }
}
.mobile-top-header {
  height: 38px;
  width: 100%;
  &-item {
    flex: 1;
  }
  .nav-user-zip-code {
    font-size: pxToRem(12px);
    justify-content: flex-end;
    padding-right: $spacing-md;
  }
  .lang-switcher {
    height: 38px;
    &-select {
      color: var(--white);
      font-size: pxToRem(12px);
    }
  }
}
.nav-user-zip-code {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: pxToRem(15px);
  font-weight: 800;
  margin-left: $spacing-md;
  text-decoration: underline;
}

.header-banner-event-cls {
  // CLS BANNER EVENT, to switch
  // height: 40px;
  height: 0;
}

.header-banner-event {
  align-items: center;
  background: var(--bg-grey);
  border-radius: $spacing-xs;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: $spacing-xxs;
  b {
    font-weight: 800;
  }
}
</style>
