import { ref } from 'vue'
import { useSnackbarStore } from '@/stores/snackbar'
import { useEmitterStore } from '@/stores/emitter'
import { useStock } from '@/composables/useStock'
import { useCartItem } from '@/composables/useCartItem'
import { useReference } from '@/composables/useReference'
import { useAnalytics } from '@/composables/Analytics/useAnalytics'
import { useReferenceApi } from '@/composables/Api/useReferenceApi'

export function useAddToCart() {
  const route = useRoute()

  const stock = ref({})
  const fastAddToCart = ref(null)
  const quantity = ref(0)
  const isUpsell = ref(false)
  const originForTracking = ref(null)

  const { $cart, $i18n } = useNuxtApp()
  const { analytics, events, trackingPath } = useAnalytics()
  const { request } = useApiRequest()
  const snackbarStore = useSnackbarStore()
  const emitter = useEmitterStore()

  const addToCart = (accepted = false) => {
    // If fastAddToCart, trace origin from referencecell, else get origin from localStorage (because we are on product page)
    const relevantOriginForTracking = fastAddToCart.value ? trackingPath(originForTracking.value) : trackingPath()
    if (fastAddToCart?.value && !isUpsell.value) {
      // Query for references to have infos on the stock company to be able to add to cart.
      const stockOfferFilter = stock?.value?.reference?.bestStock?.company_slug
        ? {
            value: stock.value.reference.bestStock.company_slug,
            query: 'merchant_uid',
            type: 'MERCHANT_UID',
          }
        : {}
      Promise.allSettled([
        request({
          req: useReferenceApi().get(stock?.value?.reference?.slug),
        }),
        request({
          req: useReferenceApi().stockOffers(stock?.value?.reference?.slug, { page: 1, perPage: 1 }, stockOfferFilter),
        }),
      ]).then(async ([refResult, stockOffersResult]) => {
        // console.log(refResult.value, stockOffersResult.value.stock_offers)
        const ref = useReference(refResult.value || {}, stockOffersResult.value.stock_offers || [])
        const fullStock = await getFullCompanyInfos(ref)
        // console.log('fullStock', fullStock)
        let item
        try {
          item = useCartItem(fullStock, quantity?.value)
        } catch (error) {
          console.log(error)
          snackbarStore.showSnackbar({
            color: 'red',
            message: $i18n.t('backendError.cart_stock_not_created'),
            timeout: 2000,
          })
          return
        }
        if (fullStock?.reference?.accreditation && !accepted) {
          emitter.emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
          return
        }
        $cart().addItem({
          ...item,
          stock: { ...item.stock, origin_for_tracking: relevantOriginForTracking },
        })
        emitter.emit('addToCart', { item, fastAddToCart: true })
        snackbarStore.showSnackbar({
          color: 'primary',
          message: $i18n.t('snackbar.productAddedToCart') + '<div class="icon-check u-bg-white" />',
          timeout: 2000,
        })
      })
    }

    emitter.emit('modalClose_confirmationModalAddToCart', 'confirmationModalAddToCart')
    if (stock?.value?.reference.accreditation && !accepted) {
      emitter.emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
      return
    }
    analytics.sendTagEvent(events.TAG_PLAN?.MKP?.CONFIRM_BUY)
    let ref = useReference(stock?.value?.o_reference || stock?.value?.reference || {})
    // We don't track if origin is cart_product_item
    if (relevantOriginForTracking !== 'cart_product_item') {
      analytics.sendEvent(events.ADD_TO_CART, {
        probance: {
          item_id: ref.uid,
          stock_id: stock?.value?.uid,
        },
        ecommerce: {
          currency: 'EUR',
          value: Number(stock?.value?.storePrice(true) * quantity?.value),
          tax: Number(ref.bestStock?.price_vat - ref.bestStock?.price) || 0,
          items: [
            {
              item_id: ref.trackingId,
              item_name: ref.name,
              name: ref.name,
              currency: 'EUR',
              discount: 0,
              item_brand: ref?.brand?.name,
              quantity: Number(quantity?.value),
              price: Number(stock?.value?.storePrice(true)),
              ...ref.categoriesName.reduce((a, b, i) => {
                return { ...a, [`item_category${i === 0 ? '' : i + 1}`]: b }
              }, {}),
              item_category4: 'product',
              ...(relevantOriginForTracking
                ? {
                    item_list_id: relevantOriginForTracking,
                    item_list_name: relevantOriginForTracking,
                  }
                : {}),
            },
          ],
        },
      })
    }

    if (!fastAddToCart?.value || isUpsell.value) {
      const item = useCartItem(stock.value, quantity?.value)
      const { $cart } = useNuxtApp()
      $cart().addItem({
        ...item,
        stock: { ...item.stock, origin_for_tracking: relevantOriginForTracking },
      })
      emitter.emit('addToCart', { item, fastAddToCart: isUpsell.value ? true : false })
    }
  }

  const getFullCompanyInfos = async reference => {
    // Get reference, order stocks like product page stocks() & selectedStock() (without route part) & get stock company
    if (reference?.availableStocks) {
      const stocks = reference.publicStocks?.map(child => {
        return useStock({ ...child, reference })
      })
      let _stock = null
      if (route.name.includes('merchant')) {
        _stock = stocks.find(s => s?.company?.slug === route.params.slug)
      }
      if (!_stock) {
        _stock = stocks?.sort((a, b) => {
          if (a.distance !== null && a.distance !== undefined) {
            return a.distance - b.distance
          }
          return a.price - b.price
        })[0]
      }
      return _stock
    }
    return null
  }

  return {
    addToCart,
    stock,
    fastAddToCart,
    quantity,
    isUpsell,
    originForTracking,
  }
}
