import { useCustomFetcher } from '@/composables/Api/useCustomFetcher'

export function useAutocompleteApi() {
  const { fetcher, backendUrl } = useCustomFetcher()

  const getMarketplace = async (filters = []) => {
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (!keywordsFilter || !keywordsFilter?.value || keywordsFilter?.value?.length < 3) return []
      const url = `${backendUrl}autocomplete/marketplace`
      const config = {
        params: {
          limit: 5,
        },
      }
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
      return fetcher.get(url, config).then(response => {
        return response.results
      })
    }
    return []
  }

  const getStock = async (companyUid, filters = []) => {
    if (filters && filters.length > 0) {
      const url = `${backendUrl}companies/${companyUid}/autocomplete/stocks`
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (!keywordsFilter || !keywordsFilter?.value || keywordsFilter?.value?.length < 3) return []
      const config = {
        params: {
          limit: 5,
        },
      }
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
      return fetcher.get(url, config).then(response => {
        return response.results
      })
    }
    return []
  }

  return {
    getMarketplace,
    getStock,
  }
}
